exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/About.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/Contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-hero-tsx": () => import("./../../../src/pages/Hero.tsx" /* webpackChunkName: "component---src-pages-hero-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/Projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-skills-tsx": () => import("./../../../src/pages/Skills.tsx" /* webpackChunkName: "component---src-pages-skills-tsx" */)
}

